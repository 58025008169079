import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from "@restart/ui/esm/Button";
import { Link } from "react-router-dom";
import HeaderApps from "../../components/headerApps";
import Footer from "../../components/footer";
import * as ApplicationsServices from "../../services/api/Applications";
import MainScreenJms from "../../assets/img/apps/main_screen_jms.png";
import ParesRimas from "../../assets/img/apps/pares-de-rimas.png";
import CartasLinguagem from "../../assets/game_cartas_de_linguagem/game-logo.png";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import abraCaixaApp from "../../assets/jogos/abra_caixa.jpeg";
import associacaoApp from "../../assets/jogos/associacao.jpeg";
import cacaApp from "../../assets/jogos/caca.jpeg";
import cartaApp from "../../assets/jogos/carta.jpeg";
import combineApp from "../../assets/jogos/combine.jpeg";
import encontreApp from "../../assets/jogos/encontre.jpeg";
import forcaApp from "../../assets/jogos/forca.jpeg";
import questionarioApp from "../../assets/jogos/questionario.jpeg";
import quizApp from "../../assets/jogos/quiz.jpeg";
import roletaApp from "../../assets/jogos/roleta.jpeg";
import vireApp from "../../assets/jogos/vire.jpeg";

const useStyles = makeStyles({
  botaoPrimeiro: {
    marginRight: "40px",
    backgroundColor: "#54B5CF",
    borderColor: "#54B5CF",
    borderRadius: "25px",
    color: "#fff",
    float: "right",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontStyle: "bold",
    fontWeight: "bold",
    height: "50px",
    margin: "40px 0 0 0",
    padding: "10px 60px 20px 60px",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#0facc4",
      color: "#fff",
    },
    "@media (max-width: 500px)": {
      backgroundColor: "#54B5CF",
      fontSize: "20px",
      padding: "10px 20px 0 20px",
      margin: "10px 0 0 0",
    },
  },
  appImageContainer: {
    position: "relative",
    width: "95%",
    height: "250px",
    borderRadius: "20px",
    overflow: "hidden",
  },
  appImage: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    borderRadius: "20px",
    overflow: "hidden",
  },
  appTitle: {
    color: "#3C6C98",
  },
  modalCloseButton: {
    backgroundColor: "#54B5CF",
    borderRadius: "25px",
    color: "#fff",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "40px",
    marginTop: "20px",
    padding: "10px 30px",
    border: "none", // Removed border to avoid the bottom border issue
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#0facc4",
      color: "#fff",
    },
  },
  modalAppImageContainer: {
    position: "relative",
    width: "90%",
    height: "150px",
    borderRadius: "20px",
    overflow: "hidden",
    marginBottom: "10px",
    cursor: "pointer", // Adicionado cursor pointer para indicar que é clicável
  },
  modalAppImage: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    borderRadius: "20px",
    overflow: "hidden",
  },
  modalAppTitle: {
    color: "#3C6C98",
    textAlign: "center",
    marginTop: "10px",
  },
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "90vh", // Added max-height to handle overflow
  overflowY: "auto", // Added overflow to handle scrolling
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
};

export default function Apps() {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [applications, setApplications] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  React.useEffect(() => {
    (async () => {
      const resp = await ApplicationsServices.findAll();
      setApplications(resp.data);
      setLoading(false);
    })();
  }, [page]);

  return (
    <div className={classes.container}>
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color={"error"} />
        </Box>
      ) : (
        <></>
      )}
      <HeaderApps />
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ padding: "20px" }}
      >
      {/*   <Grid item xs={12} md={3}>
          <a
            href={`https://jmsapp.web.app/`}
            target="_blank"
            style={{ textDecoration: "none" }}
            rel="noreferrer"
          >
            <div className={classes.appImageContainer}>
              <div
                className={classes.appImage}
                style={{ backgroundImage: `url(${MainScreenJms})` }}
              > 
              </div>
            </div>
          </a>
          <small className={classes.appTitle}>
            Jogo da Memória Sonoro (JMS)
          </small>
          <br></br>
          <br></br>
        </Grid> */}
  {/*       <Grid item xs={12} md={3}>
          <div
            onClick={handleOpen}
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            <div className={classes.appImageContainer}>
              <div
                className={classes.appImage}
                style={{ backgroundImage: `url(${ParesRimas})` }}
              > 
              </div>
            </div>
          </div>
          <small className={classes.appTitle}>Pares de Rimas (PDR)</small>
          <br></br>
          <br></br>
        </Grid> */}
    {/*     <Grid item xs={12} md={3}>
          <Link
            to="/admin/games"
            style={{ textDecoration: "none" }} // Remover a decoração padrão do link
          >
            <div className={classes.appImageContainer}>
              <div
                className={classes.appImage}
                style={{ backgroundImage: `url(${CartasLinguagem})` }}
              > 
              </div>
            </div>
            <small className={classes.appTitle}>Cartas de Linguagem</small>
            <br />
            <br />
          </Link>
        </Grid> */}
        {applications.map((app, index) => (
          <Grid item xs={12} md={3} key={index} onClick={!app.link ? handleOpen : ''}>
            {app.link ? (
              <a href={app.link} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                <div className={classes.appImageContainer}>
                  <div className={classes.appImage} style={{ backgroundImage: `url(${app.thumbnail})` }}></div>
                </div>
              </a>
            ) : (
              <Link to={app.internalLink || "#"} style={{ textDecoration: "none" }}>
                <div className={classes.appImageContainer}>
                  <div className={classes.appImage} style={{ backgroundImage: `url(${app.thumbnail})` }}></div>
                </div>
              </Link>
            )}
            <small className={classes.appTitle}>{app.title}</small>
            <br />
            <br />
          </Grid>
        ))}
      </Grid>
     
      <Button
        className={classes.botaoPrimeiro}
        href="/admin/profissional/perfil"
      >
        VOLTAR
      </Button>

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Selecione o jogo
          </Typography>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "20px" }}
          >
            <Grid item xs={6} md={4} style={{ position: "relative" }}>
              <a
                href="https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=25&fontStackId=0"
                target="_blank"
                style={{ display: "block", position: "relative" }}
              >
                <img
                  src={combineApp} // Substitua pelo caminho da sua imagem
                  alt="Miniatura personalizada"
                  style={{ width: "100%", height: "100%" }}
                />
                <iframe
                  className={classes.modalAppImage}
                  src={`https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=25&fontStackId=0`}
                  title="App 1"
                  frameBorder="0"
                  style={{
                    pointerEvents: "none",
                    width: "100%",
                    height: "100%",
                    visibility: "hidden",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                ></iframe>
                <div
                  style={{
                    position: "absolute",
                    top: "10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <small
                    className={classes.modalAppTitle}
                    style={{
                      color: "white",
                      marginBottom: "4px",
                      textShadow: "0 0 3px blue, 0 0 5px blue",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      padding: "10px 16px",
                      borderRadius: "8px",
                      display: "inline-block",
                      fontSize: "1.2em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Pares de Rimas - Combine os Pares
                  </small>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <PlayArrowIcon style={{ color: "white" }} />
                </div>
              </a>
            </Grid>

            <Grid item xs={6} md={4} style={{ position: "relative" }}>
              <a
                href="https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=3&fontStackId=0"
                target="_blank"
                style={{ display: "block", position: "relative" }}
              >
                <img
                  src={associacaoApp} // Substitua pelo caminho da sua imagem
                  alt="Miniatura personalizada"
                  style={{ width: "100%", height: "100%" }}
                />
                <iframe
                  className={classes.modalAppImage}
                  src={`https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=3&fontStackId=0`}
                  title="App 2"
                  frameBorder="0"
                  style={{
                    pointerEvents: "none",
                    width: "100%",
                    height: "100%",
                    visibility: "hidden",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                ></iframe>
                <div
                  style={{
                    position: "absolute",
                    top: "10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <small
                    className={classes.modalAppTitle}
                    style={{
                      color: "white",
                      marginBottom: "4px",
                      textShadow: "0 0 3px blue, 0 0 5px blue",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      padding: "10px 16px",
                      borderRadius: "8px",
                      display: "inline-block",
                      fontSize: "1.2em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Pares de Rimas - Associação
                  </small>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <PlayArrowIcon style={{ color: "white" }} />
                </div>
              </a>
            </Grid>
            <Grid item xs={6} md={4} style={{ position: "relative" }}>
              <a
                href="https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=46&fontStackId=0"
                target="_blank"
                style={{ display: "block", position: "relative" }}
              >
                <img
                  src={encontreApp} // Substitua pelo caminho da sua imagem
                  alt="Miniatura personalizada"
                  style={{ width: "100%", height: "200px" }}
                />
                <iframe
                  className={classes.modalAppImage}
                  src={`https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=46&fontStackId=0`}
                  title="App 3"
                  frameBorder="0"
                  style={{
                    pointerEvents: "none",
                    width: "100%",
                    height: "100%",
                    visibility: "hidden",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                ></iframe>
                <div
                  style={{
                    position: "absolute",
                    top: "10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <small
                    className={classes.modalAppTitle}
                    style={{
                      color: "white",
                      marginBottom: "4px",
                      textShadow: "0 0 3px blue, 0 0 5px blue",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      padding: "10px 16px",
                      borderRadius: "8px",
                      display: "inline-block",
                      fontSize: "1.2em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Pares de Rimas - Encontre a Combinação
                  </small>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <PlayArrowIcon style={{ color: "white" }} />
                </div>
              </a>
            </Grid>
            <Grid item xs={6} md={4} style={{ position: "relative" }}>
              <a
                href="https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=5&fontStackId=0"
                target="_blank"
                style={{ display: "block", position: "relative" }}
              >
                <img
                  src={questionarioApp} // Substitua pelo caminho da sua imagem
                  alt="Miniatura personalizada"
                  style={{ width: "100%", height: "200px" }}
                />
                <iframe
                  className={classes.modalAppImage}
                  src="https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=5&fontStackId=0"
                  title="App 4"
                  frameBorder="0"
                  style={{
                    pointerEvents: "none",
                    width: "100%",
                    height: "100%",
                    visibility: "hidden",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                ></iframe>
                <div
                  style={{
                    position: "absolute",
                    top: "10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <small
                    className={classes.modalAppTitle}
                    style={{
                      color: "white",
                      marginBottom: "4px",
                      textShadow: "0 0 3px blue, 0 0 5px blue",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      padding: "10px 16px",
                      borderRadius: "8px",
                      display: "inline-block",
                      fontSize: "1.2em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Pares de Rimas - Questionário
                  </small>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <PlayArrowIcon style={{ color: "white" }} />
                </div>
              </a>
            </Grid>
            <Grid item xs={6} md={4} style={{ position: "relative" }}>
              <a
                href="https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=21&templateId=69&fontStackId=0"
                target="_blank"
                style={{ display: "block", position: "relative" }}
              >
                <img
                  src={quizApp} // Substitua pelo caminho da sua imagem
                  alt="Miniatura personalizada"
                  style={{ width: "100%", height: "200px" }}
                />
                <iframe
                  className={classes.modalAppImage}
                  src={`https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=21&templateId=69&fontStackId=0`}
                  title="App 5"
                  frameBorder="0"
                  style={{
                    pointerEvents: "none",
                    width: "100%",
                    height: "100%",
                    visibility: "hidden",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                ></iframe>
                <div
                  style={{
                    position: "absolute",
                    top: "10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <small
                    className={classes.modalAppTitle}
                    style={{
                      color: "white",
                      marginBottom: "4px",
                      textShadow: "0 0 3px blue, 0 0 5px blue",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      padding: "10px 16px",
                      borderRadius: "8px",
                      display: "inline-block",
                      fontSize: "1.2em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Pares de Rimas - Game Show de TV
                  </small>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <PlayArrowIcon style={{ color: "white" }} />
                </div>
              </a>
            </Grid>
            <Grid item xs={6} md={4} style={{ position: "relative" }}>
              <a
                href="https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=44&templateId=73&fontStackId=0"
                target="_blank"
                style={{ display: "block", position: "relative" }}
              >
                <img
                  src={forcaApp} // Substitua pelo caminho da sua imagem
                  alt="Miniatura personalizada"
                  style={{ width: "100%", height: "200px" }}
                />
                <iframe
                  className={classes.modalAppImage}
                  src={`https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=44&templateId=73&fontStackId=0`}
                  title="App 5"
                  frameBorder="0"
                  style={{
                    pointerEvents: "none",
                    width: "100%",
                    height: "100%",
                    visibility: "hidden",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                ></iframe>
                <div
                  style={{
                    position: "absolute",
                    top: "10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <small
                    className={classes.modalAppTitle}
                    style={{
                      color: "white",
                      marginBottom: "4px",
                      textShadow: "0 0 3px blue, 0 0 5px blue",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      padding: "10px 16px",
                      borderRadius: "8px",
                      display: "inline-block",
                      fontSize: "1.2em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Pares de Rimas - Jogo da Forca
                  </small>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <PlayArrowIcon style={{ color: "white" }} />
                </div>
              </a>
            </Grid>
            <Grid item xs={6} md={4} style={{ position: "relative" }}>
              <a
                href="https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=8&fontStackId=0"
                target="_blank"
                style={{ display: "block", position: "relative" }}
              >
                <img
                  src={roletaApp} // Substitua pelo caminho da sua imagem
                  alt="Miniatura personalizada"
                  style={{ width: "100%", height: "200px" }}
                />
                <iframe
                  className={classes.modalAppImage}
                  src={`https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=8&fontStackId=0`}
                  title="App 5"
                  frameBorder="0"
                  style={{
                    pointerEvents: "none",
                    width: "100%",
                    height: "100%",
                    visibility: "hidden",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                ></iframe>
                <div
                  style={{
                    position: "absolute",
                    top: "10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <small
                    className={classes.modalAppTitle}
                    style={{
                      color: "white",
                      marginBottom: "4px",
                      textShadow: "0 0 3px blue, 0 0 5px blue",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      padding: "10px 16px",
                      borderRadius: "8px",
                      display: "inline-block",
                      fontSize: "1.2em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Pares de Rimas - Roleta Aleatória
                  </small>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <PlayArrowIcon style={{ color: "white" }} />
                </div>
              </a>
            </Grid>
            <Grid item xs={6} md={4} style={{ position: "relative" }}>
              <a
                href="https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=75&fontStackId=0"
                target="_blank"
                style={{ display: "block", position: "relative" }}
              >
                <img
                  src={vireApp} // Substitua pelo caminho da sua imagem
                  alt="Miniatura personalizada"
                  style={{ width: "100%", height: "200px" }}
                />
                <iframe
                  className={classes.modalAppImage}
                  src={`https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=75&fontStackId=0`}
                  title="App 5"
                  frameBorder="0"
                  style={{
                    pointerEvents: "none",
                    width: "100%",
                    height: "100%",
                    visibility: "hidden",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                ></iframe>
                <div
                  style={{
                    position: "absolute",
                    top: "10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <small
                    className={classes.modalAppTitle}
                    style={{
                      color: "white",
                      marginBottom: "4px",
                      textShadow: "0 0 3px blue, 0 0 5px blue",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      padding: "10px 16px",
                      borderRadius: "8px",
                      display: "inline-block",
                      fontSize: "1.2em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Pares de Rimas - Vire as peças
                  </small>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <PlayArrowIcon style={{ color: "white" }} />
                </div>
              </a>
            </Grid>
            <Grid item xs={6} md={4} style={{ position: "relative" }}>
              <a
                href="https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=30&fontStackId=0"
                target="_blank"
                style={{ display: "block", position: "relative" }}
              >
                <img
                  src={abraCaixaApp} // Substitua pelo caminho da sua imagem
                  alt="Miniatura personalizada"
                  style={{ width: "100%", height: "200px" }}
                />
                <iframe
                  className={classes.modalAppImage}
                  src={`https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=30&fontStackId=0`}
                  title="App 5"
                  frameBorder="0"
                  style={{
                    pointerEvents: "none",
                    width: "100%",
                    height: "100%",
                    visibility: "hidden",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                ></iframe>
                <div
                  style={{
                    position: "absolute",
                    top: "10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <small
                    className={classes.modalAppTitle}
                    style={{
                      color: "white",
                      marginBottom: "4px",
                      textShadow: "0 0 3px blue, 0 0 5px blue",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      padding: "10px 16px",
                      borderRadius: "8px",
                      display: "inline-block",
                      fontSize: "1.2em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Pares de Rimas - Abra a caixa
                  </small>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <PlayArrowIcon style={{ color: "white" }} />
                </div>
              </a>
            </Grid>
            <Grid item xs={6} md={4} style={{ position: "relative" }}>
              <a
                href="https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=10&fontStackId=0"
                target="_blank"
                style={{ display: "block", position: "relative" }}
              >
                <img
                  src={cacaApp} // Substitua pelo caminho da sua imagem
                  alt="Miniatura personalizada"
                  style={{ width: "100%", height: "200px" }}
                />
                <iframe
                  className={classes.modalAppImage}
                  src={`https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=10&fontStackId=0`}
                  title="App 5"
                  frameBorder="0"
                  style={{
                    pointerEvents: "none",
                    width: "100%",
                    height: "100%",
                    visibility: "hidden",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                ></iframe>
                <div
                  style={{
                    position: "absolute",
                    top: "10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <small
                    className={classes.modalAppTitle}
                    style={{
                      color: "white",
                      marginBottom: "4px",
                      textShadow: "0 0 3px blue, 0 0 5px blue",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      padding: "10px 16px",
                      borderRadius: "8px",
                      display: "inline-block",
                      fontSize: "1.2em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Pares de Rimas - Caça-palavras
                  </small>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <PlayArrowIcon style={{ color: "white" }} />
                </div>
              </a>
            </Grid>
            <Grid item xs={6} md={4} style={{ position: "relative" }}>
              <a
                href="https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=70&fontStackId=0"
                target="_blank"
                style={{ display: "block", position: "relative" }}
              >
                <img
                  src={cartaApp} // Substitua pelo caminho da sua imagem
                  alt="Miniatura personalizada"
                  style={{ width: "100%", height: "200px" }}
                />
                <iframe
                  className={classes.modalAppImage}
                  src={`https://wordwall.net/pt/embed/718009111ce041258a0bc6276052f5fc?themeId=51&templateId=70&fontStackId=0`}
                  title="App 5"
                  frameBorder="0"
                  style={{
                    pointerEvents: "none",
                    width: "100%",
                    height: "100%",
                    visibility: "hidden",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                ></iframe>
                <div
                  style={{
                    position: "absolute",
                    top: "10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <small
                    className={classes.modalAppTitle}
                    style={{
                      color: "white",
                      marginBottom: "4px",
                      textShadow: "0 0 3px blue, 0 0 5px blue",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      padding: "10px 16px",
                      borderRadius: "8px",
                      display: "inline-block",
                      fontSize: "1.2em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Pares de Rimas - Cartas aleatórias
                  </small>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <PlayArrowIcon style={{ color: "white" }} />
                </div>
              </a>
            </Grid>
          </Grid>
          <Button className={classes.modalCloseButton} onClick={handleClose}>
            FECHAR
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
